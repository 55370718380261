import React, { useState } from 'react';
import '../styles/EmployeeSettings.css';
import { checkResponseStatus } from '../utils/tokenExpiredHandler';
import '../styles/SettingsModals.css'
import config from "../config/config";

const EmployeeSettings = ({ employees, setEmployees }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);

  const handleOpenModal = (employee = null, editMode = false) => {
    setSelectedEmployee(employee);
    setShowModal(true);
    setIsEditing(editMode);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEmployee(null);
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSelectedEmployee({
      ...selectedEmployee,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const saveEmployee = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const requestBody = {
        ...selectedEmployee,
        is_separator: false, // 従業員のため false を指定
      };

      if (selectedEmployee?.id) {
        // 編集の場合（PUTリクエスト）
        const res = await fetch(`${config.apiBaseUrl}/employees/${selectedEmployee.id}`, {
          method: 'PUT',
          headers,
          body: JSON.stringify(selectedEmployee)
        });
        if (!(await checkResponseStatus(res))) return;
        if (res.ok) {
          setEmployees(employees.map(emp => emp.id === selectedEmployee.id ? selectedEmployee : emp));
        } else {
          console.error('従業員の編集に失敗しました');
        }
      } else {
        // 新規追加の場合（POSTリクエスト）
        const res = await fetch(`${config.apiBaseUrl}/employees`, {
          method: 'POST',
          headers,
          body: JSON.stringify(requestBody)
        });
        if (!(await checkResponseStatus(res))) return;
        if (res.ok) {
          const newEmployee = await res.json();
          setEmployees([...employees, newEmployee]);
        } else {
          console.error('従業員の追加に失敗しました');
        }
      }
    } catch (error) {
      console.error('従業員保存エラー:', error);
    }
    handleCloseModal();
  };

  const removeEmployee = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const res = await fetch(`${config.apiBaseUrl}/employees/${id}`, {
        method: 'DELETE',
        headers
      });
      if (!(await checkResponseStatus(res))) return;
      if (res.ok) {
        setEmployees(employees.filter(emp => emp.id !== id));
      } else {
        console.error('従業員の削除に失敗しました');
      }
    } catch (error) {
      console.error('従業員削除エラー:', error);
    }
  };

  const handleOpenDeleteModal = (employee) => {
    setEmployeeToDelete(employee);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setEmployeeToDelete(null);
    setShowDeleteModal(false);
  };


  return (
    <div className="employee-settings">
      <h2>従業員管理</h2>
      <table className="employee-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>名前</th>
            <th>在籍状態</th>
            <th className="setting-cell">操作</th>
          </tr>
        </thead>
        <tbody>
  {employees
    .filter(emp => !emp.is_separator) // is_separatorが1の従業員を除外
    .map(emp => (
      <tr key={emp.id}>
        <td>{emp.id}</td>
        <td onClick={() => handleOpenModal(emp)}>{emp.name}</td>
        <td>{emp.status ? "在籍中" : "退職"}</td>
        <td>
          <button className="employee-editbutton" onClick={() => handleOpenModal(emp, true)}>編集</button>
          <button
            className="employee-editbutton button-del"
            onClick={() => handleOpenDeleteModal(emp)}
          >
            削除
          </button>
        </td>
      </tr>
    ))}
</tbody>

      </table>

      <button className="add-button" onClick={() => handleOpenModal(null, true)}>新規追加</button>

      {showModal && (
        <div className="settings-modal-overlay">
          <div className="settings-modal-content">
            <h3 className="settings-modal-title">{isEditing ? "従業員を編集" : "従業員詳細"}</h3>
            <div className="settings-modal-textinput">
              <label>名前</label>
              <input
                type="text"
                name="name"
                value={selectedEmployee?.name || ""}
                onChange={handleInputChange}
                placeholder="名前"
                disabled={!isEditing}
              />
            </div>
            <div className="settings-modal-textinput">
              <label>役職</label>
              <input
                type="text"
                name="role"
                value={selectedEmployee?.role || ""}
                onChange={handleInputChange}
                placeholder="役割"
                disabled={!isEditing}
              />
            </div>
            <div className="settings-modal-textinput">
              <label>連絡先</label>
              <input
                type="text"
                name="contact_info"
                value={selectedEmployee?.contact_info || ""}
                onChange={handleInputChange}
                placeholder="連絡先"
                disabled={!isEditing}
              />
            </div>
            <div>
              <label className="settings-modal-checkbox">
                在籍中
                <input
                  type="checkbox"
                  name="status"
                  checked={selectedEmployee?.status || false}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </label>
            </div>
            <div className="modal-actions">
              {isEditing && <button onClick={saveEmployee}>保存</button>}
              <button className="button-del" onClick={handleCloseModal}>閉じる</button>
            </div>
          </div>
        </div>
      )}
      {showDeleteModal && (
        <div className="settings-modal-overlay">
          <div className="settings-modal-content">
            <h3 className="settings-modal-title">確認</h3>
            <p>従業員「{employeeToDelete?.name}」を削除しますか？</p>
            <div className="settings-modal-actions">
              <button
                className="button-del"
                onClick={() => {
                  removeEmployee(employeeToDelete.id);
                  handleCloseDeleteModal();
                }}
              >
                削除
              </button>
              <button onClick={handleCloseDeleteModal}>いいえ</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeSettings;
