import React, { useMemo, useState, useEffect } from 'react';
import '../styles/ShiftTable.css';
import { getComplementaryColor } from '../utils/colorUtils';
import config from "../config/config";
import { getAuthHeaders } from '../utils/apiUtils';
import { checkResponseStatus } from '../utils/tokenExpiredHandler';
import { getShiftStyle } from '../utils/shiftStyleHelper';

const ShiftTable = ({ holidayDates, employees, daysInRange, shiftData, getShiftDisplay, handleCellClick, hoveredRow, hoveredCol, shifts, setHoveredRow, setHoveredCol, currentUser, setShiftData, shiftYear, shiftMonth, }) => {
  const [isEmployeeModalOpen, setEmployeeModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const today = new Date();
  const todayIndex = daysInRange.findIndex(date =>
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );

  const isTodayColumn = (index) => index === todayIndex;

  // ログイン中のユーザーに紐づいた従業員ID
  const currentEmployeeId = currentUser?.employee_id;

  //従業員情モーダル表示
  const openEmployeeModal = async (employeeId) => {
    const employee = employees.find(emp => emp.id === employeeId);
    if (!employee) {
      console.error('Employee not found for ID:', employeeId);
      return;
    }

    const selectedMonth = `${shiftYear}-${String(shiftMonth).padStart(2, '0')}`; // YYYY-MM形式に整形

    try {
      // 従業員の統計データを取得
      const stats = await fetchEmployeeStats(employeeId, selectedMonth);
      if (!stats) throw new Error('Failed to fetch stats');

      // ユーザーIDを取得
      const userId = await fetchUserIdByEmployeeId(employeeId);
      console.log(userId);
      if (!userId) throw new Error('Failed to fetch user ID');

      // 最終ログイン日時を取得
      const lastLogin = await fetchLastLoginByUserId(userId);

      // データをモーダル用にセット
      setSelectedEmployee({
        ...employee,
        stats: {
          totalHours: stats.monthData.total_hours || 0,
          holidayCount: stats.monthData.holiday_count || 0,
          specialShiftCount: stats.monthData.special_shift_count || 0,
          annualLeaveUsed: stats.annualData.annual_leave_used || 0,
        },
        lastLogin, // 最終ログイン日時をセット
      });

      setEmployeeModalOpen(true);
    } catch (error) {
      console.error('Error opening employee modal:', error);
      alert('データの取得に失敗しました。');
    }
  };


  const closeEmployeeModal = () => {
    setEmployeeModalOpen(false);
    setSelectedEmployee(null);
  };

  const cellStyles = useMemo(() => {
    const newStyles = {};

    employees.forEach(employee => {
      daysInRange.forEach(date => {
        // 日付をYYYY-MM-DD形式に変換
        const formattedDate = new Date(date).toISOString().split('T')[0];
        const cellKey = `${employee.id}-${formattedDate}`;
        const cellData = shiftData[cellKey];
        const defaultColor = shifts.find(shift => shift.shift_code === cellData?.shift_code)?.default_color || '#ffffff';

        // shift_color と shift_color2 に基づくスタイルを設定
        if (cellData?.shift_color2) {
          // 2色目がある場合、linear-gradientを適用
          newStyles[cellKey] = {
            backgroundImage: `linear-gradient(to bottom, ${cellData.shift_color} 50%, ${cellData.shift_color2} 50%)`
          };
        } else {
          // 1色だけの場合
          newStyles[cellKey] = {
            backgroundColor: cellData?.shift_color || defaultColor
          };
        }
      });
    });

    return newStyles;
  }, [employees, daysInRange, shiftData, shifts]);  // 必要な依存関係のみ設定


  // 日付に応じたクラスを付与する関数
  const getDayClass = (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    if (holidayDates.includes(formattedDate)) {
      return 'holiday'; // 祝日用のクラス
    }
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 ? 'sunday' : dayOfWeek === 6 ? 'saturday' : '';
  };

  // 曜日の名前を取得
  const getDayName = (date) => {
    const dayNames = ["日", "月", "火", "水", "木", "金", "土"];
    return dayNames[date.getDay()];
  };

  // シフトデータをフォーマットして表示する関数
  const getShiftDisplayWithName = (cellData) => {
    if (cellData) {
      const iconColor = cellData.shift_color ? getComplementaryColor(cellData.shift_color) : "#000000";
      const hasNoteMarker = cellData.note ? (
        <span className="note-marker" style={{ color: iconColor }}>●</span>
      ) : null;

      const adjustmentSymbol = cellData.time_adjustment > 0 ? "+"
        : cellData.time_adjustment < 0 ? "-"
          : "";

      const shiftCodes = [cellData.shift_code, cellData.shift_code2]
        .filter(Boolean) // nullや空文字を除外
        .join(""); // シフトコードを連結

      const displayText = `${shiftCodes}${adjustmentSymbol}`;

      if (cellData?.is_temporary) {
        return (
          <div>
            <div className="temporary-shift">
              {formatTime(cellData.temp_start)}
              <br />
              {formatTime(cellData.temp_end)}
            </div>
            {hasNoteMarker}
          </div>
        );
      }

      return (
        <div className="regular-shift">
          <div>
            {displayText}
          </div>
          {hasNoteMarker}
        </div>
      );
    }
    return '';
  };

  const handleMouseEnterCell = (row, col) => {
    setHoveredRow(row);
    setHoveredCol(col);
  };

  const handleMouseLeaveCell = () => {
    setHoveredRow(null);
    setHoveredCol(null);
  };

  const formatTime = (time) => {
    if (!time) return ''; // nullやundefinedの場合は空文字を返す
    const [hours, minutes] = time.split(':'); // 時間と分を取り出す
    return `${parseInt(hours, 10)}:${minutes}`; // parseIntで先頭の0を削除
  };

  //モバイル対応

  const transposeTable = (employees, daysInRange, shiftData) => {
    return daysInRange.map((date) => ({
      date: date.toISOString().split("T")[0], // 正確な日付文字列
      header: `${date.getDate()} (${["日", "月", "火", "水", "木", "金", "土"][date.getDay()]})`, // 日付 + 曜日
      cells: employees.map((employee) => {
        const cellKey = `${employee.id}-${date.toISOString().split("T")[0]}`;
        return {
          name: employee.name,
          cellData: shiftData[cellKey],
          cellKey,
        };
      }),
    }));
  };

  const [isTransposed, setIsTransposed] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      setIsTransposed(isMobile);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize); // 方向変更も監視
    handleResize(); // 初期実行

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);


  const transposedRows = useMemo(() => {
    return isTransposed ? transposeTable(employees, daysInRange, shiftData) : null;
  }, [isTransposed, employees, daysInRange, shiftData]);

  const toggleTranspose = () => {
    setIsTransposed(!isTransposed);
  };

  useEffect(() => {
    // WebSocket接続
    const ws = new WebSocket(`${config.wsBaseUrl}`);

    ws.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('Received message from server:', message); // サーバーからの通知をログ出力

      if (message.type === 'SHIFT_UPDATE') {
        setShiftData((prevData) => {
          const updatedData = {
            ...prevData,
            [message.key]: message.payload, // サーバーからのキーを使用
          };
          console.log('Updated shift data:', updatedData); // 確認ログ
          return updatedData;
        });
      }

      if (message.type === 'DELETE') {
        setShiftData((prevData) => {
          const updatedData = { ...prevData };
          delete updatedData[message.key]; // サーバーからのキーを使用して削除
          console.log('Updated shift data after delete:', updatedData); // 確認ログ
          return updatedData;
        });
      }
    };




    ws.onclose = () => {
      console.log('WebSocket closed');
    };

    return () => {
      ws.close(); // クリーンアップ
    };
  }, [setShiftData]);


  const handleCellClickForTransposed = (rowIndex, colIndex) => {
    if (isTransposed) {
      handleCellClick(colIndex, rowIndex); // 縦表示の場合は行と列を入れ替え
    } else {
      handleCellClick(rowIndex, colIndex); // 横表示の場合はそのまま
    }
  };

  const adjustMonth = (month) => {
    const [year, monthNum] = month.split('-').map(Number);

    // 前月の計算
    const newMonthNum = monthNum === 1 ? 12 : monthNum - 1; // 1月の場合は12月にする
    const newYear = monthNum === 1 ? year - 1 : year;       // 1月の場合は前年にする

    // 新しい月を返す (YYYY-MM形式)
    return `${newYear}-${String(newMonthNum).padStart(2, '0')}`;
  };

  const fetchEmployeeStats = async (employeeId, month) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { 'Authorization': `Bearer ${token}` };

      // 月を補正 (-1月)
      const adjustedMonth = adjustMonth(month);

      // 月次データ取得
      const monthTotalResponse = await fetch(`${config.apiBaseUrl}/monthtotal?month=${adjustedMonth}&staffId=${employeeId}`, { headers });
      if (!(await checkResponseStatus(monthTotalResponse))) return;
      if (!monthTotalResponse.ok) throw new Error('Failed to fetch month data');
      const monthData = await monthTotalResponse.json();

      // 年度データ取得
      const currentYear = new Date().getFullYear();
      const annualTotalResponse = await fetch(`${config.apiBaseUrl}/annual-total?staffId=${employeeId}&year=${currentYear}`, { headers });
      if (!(await checkResponseStatus(annualTotalResponse))) return;
      if (!annualTotalResponse.ok) throw new Error('Failed to fetch annual data');
      const annualData = await annualTotalResponse.json();

      return { monthData, annualData };
    } catch (error) {
      console.error('Error fetching employee stats:', error);
      return null;
    }
  };

  //日付クリックモーダル
  const [isDateModalOpen, setDateModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [hourlyData, setHourlyData] = useState([]);
  const [loadingHourlyData, setLoadingHourlyData] = useState(false);
  const [errorHourlyData, setErrorHourlyData] = useState(null);


  const openDateModal = (date) => {
    setSelectedDate(date);
    setDateModalOpen(true);
  };

  const closeDateModal = () => {
    setDateModalOpen(false);
    setSelectedDate(null);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [activeTab, setActiveTab] = useState('stats'); // 初期状態は統計タブ

  const fetchHourlyDistribution = async (date) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await fetch(`${config.apiBaseUrl}/hourly-distribution?date=${date}`, { headers });
      if (!(await checkResponseStatus(response))) return;
      if (!response.ok) throw new Error('Failed to fetch hourly distribution data');
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching hourly distribution:', error);
      return null;
    }
  };

  const hours = Array.from({ length: 24 }, (_, i) => (i + 6) % 24); // 6:00 ～ 翌5:00の順
  const mergedData = hours.map((hour) => {
    const dataForHour = hourlyData.find((row) => row.hour_column === hour);
    return {
      hour_column: hour,
      total_staff: dataForHour ? dataForHour.total_staff : 0,
    };
  });

  const getAdjustedDate = (date) => {
    const adjustedDate = new Date(date);
    adjustedDate.setDate(adjustedDate.getDate() + 1);
    return adjustedDate.toISOString().split('T')[0]; // YYYY-MM-DD形式
  };



  // モーダルを開いたときにデータを取得
  useEffect(() => {
    if (isDateModalOpen && selectedDate) {
      const fetchData = async () => {
        setLoadingHourlyData(true);
        setErrorHourlyData(null);

        const adjustedDate = getAdjustedDate(selectedDate); // 日付を調整
        const data = await fetchHourlyDistribution(adjustedDate);
        if (data) {
          setHourlyData(data);
        } else {
          setErrorHourlyData('データの取得に失敗しました。');
        }

        setLoadingHourlyData(false);
      };

      fetchData();
    }
  }, [isDateModalOpen, selectedDate]);

  //従業員IDからユーザーIDを取得
  const fetchUserIdByEmployeeId = async (employeeId) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { 'Authorization': `Bearer ${token}` };

      const response = await fetch(`${config.apiBaseUrl}/users/by-employee/${employeeId}`, { headers });
      if (!(await checkResponseStatus(response))) return;
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Failed to fetch user ID:', response.status, errorText);
        throw new Error(`Failed to fetch user ID: ${errorText}`);
      }


      const data = await response.json();
      console.log(data.userid);
      return data.userid;
    } catch (error) {
      console.error('Error fetching user ID by employee ID:', error);
      return null;
    }
  };



  //ユーザーIDから最終ログイン日時を取得
  const fetchLastLoginByUserId = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      const headers = { 'Authorization': `Bearer ${token}` };

      const response = await fetch(`${config.apiBaseUrl}/auth/last-login/${userId}`, { headers });
      if (!(await checkResponseStatus(response))) return;
      if (!response.ok) throw new Error('Failed to fetch last login by user ID');
      const data = await response.json();

      return data.lastLogin; // APIが`lastLogin`を返す想定
    } catch (error) {
      console.error('Error fetching last login by user ID:', error);
      return null;
    }
  };

  //staff_idをemployeesテーブルから名前に変換
  const getEmployeeName = (id) => {
    const employee = employees.find(emp => emp.id === id);
    return employee ? employee.name : '不明';
  };

  //編集履歴取得
  const [shiftHistory, setShiftHistory] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [errorHistory, setErrorHistory] = useState(null);

  useEffect(() => {
    if (isDateModalOpen && activeTab === 'history' && selectedDate) {
      const fetchShiftHistory = async () => {
        setLoadingHistory(true);
        setErrorHistory(null);

        try {
          const token = localStorage.getItem('token');
          const headers = { Authorization: `Bearer ${token}` };
          const response = await fetch(
            `${config.apiBaseUrl}/shift-history?date=${selectedDate.toISOString().split('T')[0]}`,
            { headers }
          );
          if (!(await checkResponseStatus(response))) return;
          if (!response.ok) throw new Error('Failed to fetch shift history');
          const data = await response.json();
          console.log(data);
          console.log(data.details);
          setShiftHistory(data);
        } catch (error) {
          console.error('Error fetching shift history:', error);
          setErrorHistory('編集履歴の取得に失敗しました。');
        } finally {
          setLoadingHistory(false);
        }
      };

      fetchShiftHistory();
    }
  }, [isDateModalOpen, activeTab, selectedDate]);

  const mapActionType = (type) => {
    switch (type) {
      case 'DELETE':
        return '消去';
      case 'UPDATE':
        return '更新';
      case 'CREATE':
        return '作成';
      default:
        return type; // 想定外の値はそのまま返す
    }
  };


  return (
    <div>
      <button onClick={toggleTranspose} className="no-print">{isTransposed ? "元に戻す" : "行列を入れ替え"}</button>
      {!isTransposed ? (



        <table className={`shift-table ${isTransposed ? "transposed" : ""}`}>
          <thead>
            <tr>
              <th>従業員</th>
              {daysInRange.map((date, i) => (
                <th key={`dayname-${i}`} className={`weekday-cell ${getDayClass(date)} ${isTodayColumn(i) ? 'today-column today-top' : ''} ${hoveredCol === i ? 'highlight-column' : ''}`}>
                  {getDayName(date)}
                </th>
              ))}
            </tr>
            <tr>
              <th></th>
              {daysInRange.map((date, i) => (
                <th
                  key={i}
                  className={`weekday-cell ${getDayClass(date)} ${isTodayColumn(i) ? 'today-column' : ''} ${hoveredCol === i ? 'highlight-column' : ''}`}
                  onClick={() => openDateModal(date)} // 日付をクリックしたときにモーダルを開く
                >
                  {date.getDate()}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {employees
              .filter(employee => employee.status) // 在籍中の従業員のみ表示
              .sort((a, b) => a.sort_no - b.sort_no) // sort_noを基準に昇順ソート
              .map((employee, rowIndex, filteredEmployees) => (
                <tr key={employee.id} className={`${hoveredRow === rowIndex ? 'highlight-row' : ''} ${employee.id === currentEmployeeId ? 'highlight-employee' : ''}`}>
                  <td onClick={() => openEmployeeModal(employee.id)}>{employee.name}</td>
                  {daysInRange.map((date, colIndex) => {
                    const formattedDate = new Date(date).toISOString().split('T')[0];
                    const cellKey = `${employee.id}-${formattedDate}`;
                    const todayClass = isTodayColumn(colIndex) ?
                      (rowIndex === filteredEmployees.length - 1 ? 'today-column today-bottom' : 'today-column') : '';

                    return (
                      <td
                        key={colIndex}
                        className={`shift-time ${todayClass}  ${hoveredRow === rowIndex && hoveredCol === colIndex ? 'highlight' : ''}`}
                        onMouseEnter={() => handleMouseEnterCell(rowIndex, colIndex)}
                        onMouseLeave={handleMouseLeaveCell}
                        onClick={() => handleCellClickForTransposed(rowIndex, colIndex)}
                        style={cellStyles[cellKey] || {}}
                      >
                        {shiftData[cellKey] ? getShiftDisplayWithName(shiftData[cellKey]) : ''}
                      </td>
                    );
                  })}
                </tr>
              ))}

          </tbody>

        </table>
      ) : ( //ここから縦表示用
        <div className="shift-table-container">
          <table className={`shift-table ${isTransposed ? "transposed" : ""}`}>
            <thead className="head_transposed">
              <tr>
                <th>日付</th>
                {employees.map((employee) => (
                  <th key={employee.id} className={employee.id === currentUser.employee_id ? 'highlight-employee' : ''} onClick={() => openEmployeeModal(employee.id)}>{employee.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {transposedRows.map((row, rowIndex) => {
                const isToday = new Date(row.date).toDateString() === new Date().toDateString(); // 当日判定

                return (
                  <tr key={rowIndex}>
                    {/* 日付列 */}
                    <td
                      className={isToday ? 'today-transposed-date' : ''}
                      onClick={() => openDateModal(new Date(row.date))} // 日付をモーダルに渡す
                    >
                      {row.header}
                    </td>

                    {/* 従業員セル */}
                    {row.cells.map((cell, colIndex) => {
                      let todayClass = '';
                      if (isToday) {
                        if (colIndex === 0) {
                          todayClass = 'today-transposed-left'; // 左端のセル
                        } else if (colIndex === row.cells.length - 1) {
                          todayClass = 'today-transposed-right'; // 右端のセル
                        } else {
                          todayClass = 'today-transposed-middle'; // 中間のセル
                        }
                      }

                      return (
                        <td
                          key={cell.cellKey}
                          className={`shift-time ${todayClass}`}
                          onClick={() => handleCellClickForTransposed(rowIndex, colIndex)} // 横表示と同じイベントを使用
                          style={{
                            ...cellStyles[cell.cellKey],
                            backgroundColor: cellStyles[cell.cellKey]?.backgroundColor || "#ffffff",
                          }}
                        >
                          {cell.cellData ? getShiftDisplayWithName(cell.cellData) : ""}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>


          </table>
        </div>
      )}

      {/* 従業員モーダル */}
      {isEmployeeModalOpen && selectedEmployee && (
        <div className="modal-overlay" onClick={closeEmployeeModal}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>

            <h2>{selectedEmployee.name}</h2>
            {/* <p>役職: {selectedEmployee.role}</p> */}
            <p>連絡先: {selectedEmployee.contact_info}</p>

            {/* 月次データの表示 */}
            {selectedEmployee.stats ? (
              <>
                <p>稼働時間: {selectedEmployee.stats.totalHours || 0} 時間</p>
                <p>休日数: {selectedEmployee.stats.holidayCount || 0} 日</p>
                <p>有給使用数: {selectedEmployee.stats.specialShiftCount || 0} 回</p>
                <p>今年度有給使用数: {selectedEmployee.stats.annualLeaveUsed || 0} 回</p>
                <p>最終ログイン日時: {new Date(selectedEmployee.lastLogin).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })}</p>
              </>
            ) : (
              <p>データを読み込み中...</p>
            )}

            <button onClick={closeEmployeeModal}>閉じる</button>
          </div>
        </div>
      )}

      {/* 日付モーダル */}
      {isDateModalOpen && (
        <div className="modal-overlay" onClick={closeDateModal}>
          <div className="date-modal" onClick={(e) => e.stopPropagation()}>
            <h2>日付: {selectedDate && selectedDate.toLocaleDateString()}</h2>

            <div className="tab-buttons">
              <button
                className={activeTab === 'stats' ? 'active-tab' : ''}
                onClick={() => handleTabChange('stats')}
              >
                時間と人数
              </button>
              <button
                className={activeTab === 'history' ? 'active-tab' : ''}
                onClick={() => handleTabChange('history')}
              >
                編集履歴
              </button>
            </div>

            <div className="tab-content">
              {activeTab === 'stats' && (
                <div>
                  {loadingHourlyData && <p>データを読み込み中...</p>}
                  {errorHourlyData && <p style={{ color: 'red' }}>{errorHourlyData}</p>}
                  {!loadingHourlyData && !errorHourlyData && (
                    <table className="timenumtable">
                      <thead>
                        <tr>
                          <th>時間</th>
                          <th>人数</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mergedData.map((row) => (
                          <tr key={row.hour_column}>
                            <td>{row.hour_column}:00</td>
                            <td>{row.total_staff}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
              {activeTab === 'history' && (
                <div>
                  {loadingHistory && <p>履歴を読み込み中...</p>}
                  {errorHistory && <p style={{ color: 'red' }}>{errorHistory}</p>}
                  {!loadingHistory && !errorHistory && (
                    <table className="history-table">
                      <thead>
                        <tr>
                          <th>編集日</th>
                          <th>編集者</th>
                          <th>タイプ</th>
                          <th>対象従業員</th>
                          <th>シフトコード</th>
                        </tr>
                      </thead>
                      <tbody>
                        {shiftHistory.map((entry) => (
                          <tr key={`${entry.details.id}-${entry.action_time}`}>
                            <td>{new Date(entry.action_time).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })}</td>
                            <td>{entry.editor_name || '不明'}</td>
                            <td>{mapActionType(entry.action_type)}</td>
                            <td>{getEmployeeName(entry.details.staff_id)}</td>
                            <td>
                              {entry.details.is_temporary ? (
                                <span style={getShiftStyle('temp', entry.details.shift_color)}>
                                  {`${entry.details.temp_start || '不明'} - ${entry.details.temp_end || '不明'}`}
                                </span>
                              ) : (
                                <span style={getShiftStyle(entry.details.shift_code, entry.details.shift_color)}>
                                  {`${entry.details.shift_code || ''}${entry.details.shift_code2 || ''}${entry.details.time_adjustment === 1 ? '+' : entry.details.time_adjustment === 2 ? '-' : ''
                                    }`}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>

                    </table>
                  )}

                </div>
              )}
            </div>

            <button onClick={closeDateModal}>閉じる</button>
          </div>
        </div>
      )}


    </div>
  );
};

export default ShiftTable;
