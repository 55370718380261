import React, { useState } from 'react';
import '../styles/ShiftSymbolSettings.css';
import { checkResponseStatus } from '../utils/tokenExpiredHandler';
import '../styles/SettingsModals.css'
import config from "../config/config";

const ShiftSymbolSettings = ({ shifts, setShifts }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedShift, setSelectedShift] = useState({
    shift_code: "",
    default_start_time: "00:00:00",
    default_end_time: "00:00:00",
    duration_hours: 0,
    is_overnight: false,
    default_color: '#ffffff',
    is_holiday: false,
  });

  const openEditModal = (shift) => {
    setSelectedShift(shift);
    setIsEditModalOpen(true);
  };

  const openAddModal = () => {
    setSelectedShift({
      shift_code: "",
      default_start_time: "00:00:00",
      default_end_time: "00:00:00",
      duration_hours: 0,
      is_overnight: false,
      default_color: '#ffffff',
      is_holiday: false
    });
    setIsAddModalOpen(true);
  };

  const openDeleteModal = (shift) => {
    setSelectedShift(shift);
    setIsDeleteModalOpen(true);
  };

  const closeModal = () => {
    setIsEditModalOpen(false);
    setIsAddModalOpen(false);
    setIsDeleteModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSelectedShift({
      ...selectedShift,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const saveShift = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    try {
      const res = await fetch(`${config.apiBaseUrl}/shifts/${selectedShift.id}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(selectedShift)
      });
      if (!(await checkResponseStatus(res))) return; 
      if (res.ok) {
        setShifts(shifts.map(shift => (shift.id === selectedShift.id ? selectedShift : shift)));
      } else {
        console.error('シフトコードの編集に失敗しました');
      }
    } catch (error) {
      console.error('シフトコード保存エラー:', error);
    }
    closeModal();
  };

  const addShift = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    try {
      const res = await fetch(`${config.apiBaseUrl}/shifts`, {
        method: 'POST',
        headers,
        body: JSON.stringify(selectedShift)
      });
      if (!(await checkResponseStatus(res))) return;
      if (res.ok) {
        const newShift = await res.json();
        setShifts([...shifts, newShift]);
      } else {
        console.error('シフトコードの追加に失敗しました');
        console.log(selectedShift);
      }
    } catch (error) {
      console.error('シフトコード追加エラー:', error);
    }
    closeModal();
  };

  const deleteShift = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    try {
      const res = await fetch(`${config.apiBaseUrl}/shifts/${selectedShift.id}`, {
        method: 'DELETE',
        headers
      });
      if (!(await checkResponseStatus(res))) return;
      if (res.ok) {
        setShifts(shifts.filter(shift => shift.id !== selectedShift.id));
      } else {
        console.error('シフトコードの削除に失敗しました');
      }
    } catch (error) {
      console.error('シフトコード削除エラー:', error);
    }
    closeModal();
  };

  const formatTime = (time) => {
    // 時刻が文字列で渡される場合
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  };

  return (
    <div className="shift-symbol-settings">
      <h2>シフト設定</h2>
      <div className="shift-symbol-settings-container">
      <table className="shift-table">
        <thead>
          <tr>
            <th>シフトコード</th>
            <th>開始時間</th>
            <th>終了時間</th>
            <th>勤務時間</th>
            <th>翌日またぎ</th>
            <th>休日</th>
            <th className="setting-cell">操作</th>
          </tr>
        </thead>
        <tbody>
          {shifts.map((shift) => (
            <tr key={shift.id}>
              <td style={{ backgroundColor: shift.default_color || '#ffffff'}}>{shift.shift_code}</td>
              <td>{formatTime(shift.default_start_time)}</td>
              <td>{formatTime(shift.default_end_time)}</td>
              <td>{shift.duration_hours}</td>
              <td>{shift.is_overnight ? "はい" : "いいえ"}</td>
              <td>{shift.is_holiday ? '〇' : '✕'}</td>
              <td>
                <button onClick={() => openEditModal(shift)}>編集</button>
                <button className="button-del" onClick={() => openDeleteModal(shift)}>削除</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <button onClick={openAddModal}>追加</button>

      {/* 編集モーダル */}
      {isEditModalOpen && (
        <div className="settings-modal-overlay">
          <div className="settings-modal-content">
            <h3 className="settings-modal-title">シフト編集</h3>
            <label className="settings-modal-textinput">
              表示コード
            <input
              type="text"
              name="shift_code"
              value={selectedShift.shift_code}
              onChange={handleInputChange}
              placeholder="シフトコード"
              disabled
            />
            </label>
            <label className="settings-modal-checkbox">
              休日
              <input
                type="checkbox"
                name="is_holiday"
                checked={selectedShift.is_holiday}
                onChange={handleInputChange}
              />
            </label>
            <label className="settings-modal-textinput">
              開始時間
            <input
              type="time"
              name="default_start_time"
              value={selectedShift.default_start_time}
              onChange={handleInputChange}
              placeholder="開始時間"
            />
            </label>
            <label className="settings-modal-textinput">
              終了時間
            <input
              type="time"
              name="default_end_time"
              value={selectedShift.default_end_time}
              onChange={handleInputChange}
              placeholder="終了時間"
            />
            </label>
            <label className="settings-modal-textinput">
              稼働時間
            <input
              type="number"
              name="duration_hours"
              value={selectedShift.duration_hours}
              onChange={handleInputChange}
              placeholder="勤務時間"
            />
            </label>
            <label className="settings-modal-checkbox">
              翌日またぎ:
              <input
                type="checkbox"
                name="is_overnight"
                checked={selectedShift.is_overnight}
                onChange={handleInputChange}
              />
            </label>
            <label className="settings-modal-color">
              シフトカラー:
              <input
                className="settings-modal-colorpick"
                type="color"
                name='default_color'
                value={selectedShift.default_color}
                onChange={handleInputChange}
              />
            </label>
            <div className="modal-actions">
              <button onClick={saveShift}>保存</button>
              <button className="button-del" onClick={closeModal}>キャンセル</button>
            </div>
          </div>
        </div>
      )}

      {/* 追加モーダル */}
      {isAddModalOpen && (
        <div className="settings-modal-overlay">
          <div className="settings-modal-content">
            <h3 className="settings-modal-title">新しいシフトを追加</h3>
            <label className="settings-modal-textinput">
              表示コード
            <input
              type="text"
              name="shift_code"
              value={selectedShift.shift_code}
              onChange={handleInputChange}
              placeholder="シフトコード"
            />
            </label>
            <label className="settings-modal-checkbox">
              休日
              <input
                type="checkbox"
                name="is_holiday"
                checked={selectedShift.is_holiday}
                onChange={handleInputChange}
              />
            </label>
            <label className="settings-modal-textinput">
              開始時間
            <input
              type="time"
              name="default_start_time"
              value={selectedShift.default_start_time}
              onChange={handleInputChange}
              placeholder="開始時間"
            />
            </label>
            <label className="settings-modal-textinput">
              終了時間
            <input
              type="time"
              name="default_end_time"
              value={selectedShift.default_end_time}
              onChange={handleInputChange}
              placeholder="終了時間"
            />
            </label>
            <label className="settings-modal-textinput">
            稼働時間
            <input
              type="number"
              name="duration_hours"
              value={selectedShift.duration_hours}
              onChange={handleInputChange}
              placeholder="勤務時間"
            />
            </label>
            <label className="settings-modal-checkbox">
              翌日またぎ:
              <input
                type="checkbox"
                name="is_overnight"
                checked={selectedShift.is_overnight}
                onChange={handleInputChange}
              />
            </label>
            <label className="settings-modal-color">
              シフトカラー:
              <input
                className="settings-modal-colorpick"
                type="color"
                name='default_color'
                value={selectedShift.default_color}
                onChange={handleInputChange}
              />
            </label>
            <div className="modal-actions">
              <button onClick={addShift}>追加</button>
              <button className="button-del" onClick={closeModal}>キャンセル</button>
            </div>
          </div>
        </div>
      )}

      {/* 削除確認モーダル */}
      {isDeleteModalOpen && (
        <div className="settings-modal-overlay">
          <div className="settings-modal-content">
            <h3 className="settings-modal-title">シフト削除確認</h3>
            <p>シフト「{selectedShift.shift_code}」を削除しますか？</p>
            <div className="settings-modal-actions">
              <button className="button-del" onClick={deleteShift}>削除</button>
              <button onClick={closeModal}>キャンセル</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShiftSymbolSettings;
