import React, { useState, useEffect } from 'react';
import '../styles/EmployeeSettings.css';
import '../styles/SettingsModals.css';
import config from "../config/config";
import { checkResponseStatus } from '../utils/tokenExpiredHandler';

const ShiftTableSettings = ({ employees, setEmployees }) => {
    const [showSeparatorModal, setShowSeparatorModal] = useState(false);

    const handleOpenSeparatorModal = () => setShowSeparatorModal(true);
    const handleCloseSeparatorModal = () => setShowSeparatorModal(false);
    const [newSeparator, setNewSeparator] = useState({ name: '', status: true });
    const [showSortModal, setShowSortModal] = useState(false);

    // モーダルの開閉関数
    const handleOpenSortModal = () => setShowSortModal(true);
    const handleCloseSortModal = () => setShowSortModal(false);

    const addSeparatorToDB = async (separator) => {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            const response = await fetch(`${config.apiBaseUrl}/employees`, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    name: separator.name || '区切り要素', // デフォルト値を設定
                    role: null,
                    contact_info: null,
                    status: separator.status || true,
                    sort_no: separator.sort_no || null,
                    is_separator: true, // 区切り要素であることを明示
                }),
            });
            if (!(await checkResponseStatus(response))) return;
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`DB書き込みに失敗しました: ${errorText}`);
            }

            const newEmployee = await response.json();
            return newEmployee;
        } catch (error) {
            console.error('Error adding separator to DB:', error);
            alert('区切り要素の追加に失敗しました');
        }
    };



    const handleStatusChange = async (id, newStatus) => {
        try {
            const token = localStorage.getItem('token');

            // 編集対象の従業員情報を取得
            const employee = employees.find(emp => emp.id === id);

            const response = await fetch(`${config.apiBaseUrl}/employees/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: employee.name,           // 名前を保持
                    status: newStatus,             // 新しい状態
                    is_separator: employee.is_separator, // 区切り情報を保持
                    sort_no: employee.sort_no,     // ソート番号を保持
                }),
            });
            if (!(await checkResponseStatus(response))) return;
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to update employee status: ${errorText}`);
            }

            const updatedEmployee = await response.json();
            setEmployees((prev) =>
                prev.map((emp) => (emp.id === id ? updatedEmployee : emp))
            );
        } catch (error) {
            console.error('ステータス更新エラー:', error);
            alert('状態の更新に失敗しました');
        }
    };




    const [editingId, setEditingId] = useState(null); // 編集中の従業員ID
    const [editingName, setEditingName] = useState(''); // 編集中の名前

    const handleEditName = (id, currentName) => {
        setEditingId(id);
        setEditingName(currentName);
    };


    const saveNameToDB = async (id, newName) => {
        try {
            const token = localStorage.getItem('token');

            // 編集対象の従業員情報を取得
            const employee = employees.find(emp => emp.id === id);

            const response = await fetch(`${config.apiBaseUrl}/employees/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: newName,
                    status: employee.status,       // 既存のステータスを維持
                    is_separator: employee.is_separator, // 区切り情報も維持
                    sort_no: employee.sort_no,    // ソート番号を保持
                }),
            });
            if (!(await checkResponseStatus(response))) return;
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to update employee: ${errorText}`);
            }

            const updatedEmployee = await response.json();
            setEmployees((prev) =>
                prev.map((emp) => (emp.id === id ? updatedEmployee : emp))
            );
        } catch (error) {
            console.error('名前の更新エラー:', error);
            alert('名前の更新に失敗しました');
        }
    };




    const deleteEmployeeFromDB = async (id) => {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Bearer ${token}`,
            };

            const response = await fetch(`${config.apiBaseUrl}/employees/${id}`, {
                method: 'DELETE',
                headers,
            });
            if (!(await checkResponseStatus(response))) return;
            if (!response.ok) {
                throw new Error('DB削除に失敗しました');
            }

            // ローカル状態から削除
            setEmployees((prev) => prev.filter((emp) => emp.id !== id));
        } catch (error) {
            console.error('Error deleting employee from DB:', error);
            alert('削除に失敗しました。再試行してください。');
        }
    };

    const moveEmployee = (index, direction) => {
        const newEmployees = [...employees];
        const targetIndex = index + direction;

        // インデックス範囲を超えないようにする
        if (targetIndex < 0 || targetIndex >= newEmployees.length) return;

        // sort_noをスワップ
        [newEmployees[index].sort_no, newEmployees[targetIndex].sort_no] =
            [newEmployees[targetIndex].sort_no, newEmployees[index].sort_no];

        // 並び順を反映
        setEmployees(newEmployees.sort((a, b) => a.sort_no - b.sort_no));
    };

    const saveSortOrder = async () => {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            // 全従業員のsort_noを更新
            for (const employee of employees) {
                const existingEmployee = employees.find(emp => emp.id === employee.id);

                // 現在のデータにsort_noだけを更新
                const updatedEmployee = { ...existingEmployee, sort_no: employee.sort_no };

                const response = await fetch(`${config.apiBaseUrl}/employees/${employee.id}`, {
                    method: 'PUT',
                    headers,
                    body: JSON.stringify(updatedEmployee),
                });
                if (!(await checkResponseStatus(response))) return;
                if (!response.ok) {
                    throw new Error(`Failed to update employee ${employee.id}`);
                }
            }

            handleCloseSortModal();
        } catch (error) {
            console.error('Error saving sort order:', error);
            alert('並び順の保存に失敗しました。');
        }
    };


    const initializeSortNo = () => {
        const maxSortNo = Math.max(...employees.map(emp => emp.sort_no || 0));
        const updatedEmployees = employees.map((emp, index) => ({
            ...emp,
            sort_no: emp.sort_no || maxSortNo + index + 1, // 空のものは最大値+1から順に設定
        }));
        setEmployees(updatedEmployees);
    };

    useEffect(() => {
        initializeSortNo();
    }, []);


    return (
        <div>
            <h2>シフト表示設定</h2>
            <button onClick={handleOpenSeparatorModal}>区切り表示設定</button>
            <button onClick={handleOpenSortModal}>従業員並び順設定</button>

            {showSeparatorModal && (
                <div className="settings-modal-overlay">
                    <div className="settings-modal-content">
                        <h3 className="settings-modal-title">区切り表示設定</h3>
                        <table className="employee-table">
                            <thead>
                                <tr>
                                    <th>表示名</th>
                                    <th>状態</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                            
                                {employees
                                .filter(emp => emp.is_separator === true)
                                .map((emp) => (
                                    <tr key={emp.id}>
                                        {/* 名前のセル */}
                                        <td
                                            onClick={() => {
                                                if (editingId !== emp.id) {
                                                    setEditingId(emp.id);
                                                    setEditingName(emp.name); // 現在の名前を編集用ステートにセット
                                                }
                                            }}
                                        >
                                            {editingId === emp.id ? (
                                                <input
                                                    type="text"
                                                    value={editingName}
                                                    onChange={(e) => setEditingName(e.target.value)} // 入力値をステートに反映
                                                    onBlur={() => {
                                                        saveNameToDB(emp.id, editingName); // フォーカスが外れたら保存
                                                        setEditingId(null); // 編集モードを終了
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            saveNameToDB(emp.id, editingName); // Enterキーで保存
                                                            setEditingId(null); // 編集モードを終了
                                                        }
                                                    }}
                                                    autoFocus
                                                />
                                            ) : (
                                                emp.name || <span style={{ color: '#aaa' }}>未設定</span>
                                            )}
                                        </td>

                                        {/* 状態のセル */}
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={emp.status}
                                                onChange={(e) => handleStatusChange(emp.id, e.target.checked)} // 状態変更
                                            />
                                        </td>

                                        {/* 削除ボタン */}
                                        <td>
                                            <button
                                                className="button-del"
                                                onClick={() => deleteEmployeeFromDB(emp.id)}
                                            >
                                                削除
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                        <button
                            className="add-button"
                            onClick={async () => {
                                // 新しい行のデフォルトデータ
                                const separatorToAdd = {
                                    name: '', // 空の名前
                                    status: true,
                                    is_separator: 1,
                                };

                                const addedEmployee = await addSeparatorToDB(separatorToAdd); // DBに書き込み

                                if (addedEmployee) {
                                    setEmployees([...employees, addedEmployee]); // ローカル状態を更新
                                    setEditingId(addedEmployee.id); // 編集モードに入る
                                    setEditingName(''); // 編集フィールドを空にする
                                }
                            }}
                        >
                            追加
                        </button>
                        <button onClick={handleCloseSeparatorModal}>閉じる</button>
                    </div>
                </div>
            )}


            {showSortModal && (
                <div className="settings-modal-overlay">
                    <div className="settings-modal-content">
                        <h3 className="settings-modal-title">並び順設定</h3>
                        <table className="employee-table">
                            <thead>
                                <tr><th>名前</th>
                                    <th>区分</th>
                                    <th>並び替え操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employees
                                    .filter(emp => emp.status === true) // 並び順設定はstatusがtrueのみ
                                    .sort((a, b) => a.sort_no - b.sort_no) // sort_noで昇順に並び替え
                                    .map((emp, index) => (
                                        <tr key={emp.id}>
                                            <td>{emp.name || <span style={{ color: '#aaa' }}>未設定</span>}</td>
                                            <td>{emp.is_separator === 1 ? '区切り' : '従業員'}</td>
                                            <td>
                                                <button onClick={() => moveEmployee(index, -1)}>↑</button>
                                                <button onClick={() => moveEmployee(index, 1)}>↓</button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <div className="modal-actions">
                            <button onClick={handleCloseSortModal}>閉じる</button>
                            <button onClick={saveSortOrder}>保存</button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default ShiftTableSettings;
