import React, { useState } from 'react';
import '../styles/PasswordChange.css'; // CSSファイルをインポート
import config from '../config/config'; // APIのベースURLを含む設定
import { checkResponseStatus } from '../utils/tokenExpiredHandler';

const PasswordChange = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [notification, setNotification] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // モーダル表示の状態

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setNotification('新しいパスワードが一致しません。');
      setIsModalOpen(true); // モーダルを開く
      return;
    }

    try {
      const response = await fetch(`${config.apiBaseUrl}/auth/changePassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          currentPassword,
          newPassword,
          username: localStorage.getItem('username'),
        }),
      });
      if (!(await checkResponseStatus(result))) return;
      const result = await response.json();
      if (response.ok) {
        setNotification('パスワードが正常に変更されました。');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        setNotification(result.message || 'パスワード変更に失敗しました。');
      }
    } catch (error) {
      console.error('Error changing password:', error);
      setNotification('サーバーエラーが発生しました。');
    }
    setIsModalOpen(true); // モーダルを開く
  };

  const closeModal = () => {
    setIsModalOpen(false); // モーダルを閉じる
  };

  return (
    <div className="passwordChange">
      <h3>パスワード変更</h3>
      <form className="passwordChangeBox" onSubmit={handleSubmit}>
        <label className="passwordChangeLabel">
          現在のパスワード
          <input
            className="passwordChangeText"
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
        </label>
        <label className="passwordChangeLabel">
          新しいパスワード
          <input
            className="passwordChangeText"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </label>
        <label className="passwordChangeLabel">
          新しいパスワード（確認）
          <input
            className="passwordChangeText"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </label>
        <button type="submit">変更</button>
      </form>

      {/* モーダル */}
      {isModalOpen && (
        <div className="passwordChangemodal">
          <div className="passwordChangemodal-content">
            <p>{notification}</p>
            <button onClick={closeModal}>閉じる</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PasswordChange;
