// tokenExpiredHandler.js
export const handleTokenExpired = () => {
    console.log('トークンが期限切れです。ログアウトします。');
    localStorage.removeItem('token');
    window.location.href = '/login';
  };
  
  export const checkResponseStatus = async (response) => {
    if (response.status === 403) {
      handleTokenExpired();
      return false;
    }
    return true;
  };