// src/components/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Sidebar.css';



const Sidebar = ({currentUser}) => {
  console.log('anataha',currentUser);
  return (
    <aside className="sidebar">
      <div>
      <h3>ユーザ設定</h3>
      <ul>
        <li><Link to="/settings/passwordchange">パスワード変更</Link></li>
      </ul>
      </div>
      {currentUser?.is_editable !== 0 && (
        <div>
      <h3>管理設定</h3>
      <ul>
        <li><Link to="/settings/table">シフト表設定</Link></li>
        <li><Link to="/settings/employees">従業員管理</Link></li>
        <li><Link to="/settings/shifts">シフト管理</Link></li>
        <li><Link to="/settings/users">ユーザー管理</Link></li>
      </ul>
      </div>
      )}
    </aside>
  );
};

export default Sidebar;
