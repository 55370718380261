// src/components/UserSettings.js
import React, { useState, useEffect } from 'react';
import '../styles/UserSettings.css';
import { checkResponseStatus } from '../utils/tokenExpiredHandler';
import '../styles/SettingsModals.css'
import config from "../config/config";

const UserSettings = ({ users, setUsers, employees }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    // 初期ロード時にユーザーデータを取得
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { 'Authorization': `Bearer ${token}` };
      const res = await fetch(`${config.apiBaseUrl}/users`, { headers });
      if (!(await checkResponseStatus(res))) return;
      const data = await res.json();
      setUsers(data);
    } catch (error) {
      console.error('ユーザーデータ取得エラー:', error);
    }

  };

  const handleOpenModal = (user = null, editMode = false) => {
    setSelectedUser(user);
    setShowModal(true);
    setIsEditing(editMode);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser({ ...selectedUser, [name]: value });
  };

  const saveUser = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
  
    const userData = {
      username: selectedUser.username,
      email: selectedUser.email,
      role: selectedUser.role,
      is_editable: parseInt(selectedUser.is_editable ?? 0, 10), // 未定義なら0を適用し数値型に変換
      password: selectedUser.password,
      employee_id: selectedUser.employee_id || null, // employee_idを追加
    };

    console.log('Sending userData:', userData);
  
    try {
      if (selectedUser?.id) {
        const res = await fetch(`${config.apiBaseUrl}/users/${selectedUser.id}`, {
          method: 'PUT',
          headers,
          body: JSON.stringify(userData),
        });
        if (!(await checkResponseStatus(res))) return;
        if (res.ok) {
          const updatedUser = await res.json();
          setUsers(users.map(user => user.id === updatedUser.id ? updatedUser : user));
        }
      } else {
        const res = await fetch(`${config.apiBaseUrl}/users`, {
          method: 'POST',
          headers,
          body: JSON.stringify(userData),
        });
        if (!(await checkResponseStatus(res))) return;
        if (res.ok) {
          const newUser = await res.json();
          setUsers([...users, newUser]);
        }
      }
    } catch (error) {
      console.error('ユーザー保存エラー:', error);
    }
    handleCloseModal();
  };
  

  const handleOpenDeleteModal = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setUserToDelete(null);
    setShowDeleteModal(false);
  };

  const removeUser = async (id) => {
    const token = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    try {
      const res = await fetch(`${config.apiBaseUrl}/users/${id}`, {
        method: 'DELETE',
        headers
      });
      if (!(await checkResponseStatus(res))) return;
      if (res.ok) {
        setUsers(users.filter(user => user.id !== id));
      }
    } catch (error) {
      console.error('ユーザー削除エラー:', error);
    }
  };

  

  const getEditStatus = (value) => {
    switch (value) {
      case 0:
        return "編集不可";
      case 1:
        return "シフト編集可";
      case 2:
        return "root";
      default:
        return "未定義";
    }
  };

  const getEmployeeName = (employeeId) => {
    const employee = employees.find(emp => emp.id === employeeId);
    return employee ? employee.name : "未割り当て";
  };

  return (
    <div className="user-settings">
      <h2>ユーザー管理</h2>
      <div className="user-table-wrapper">
      <table className="user-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>ユーザー名</th>
            <th>Email</th>
            <th>役割</th>
            <th>編集可否</th>
            <th>従業員名</th>
            <th className="setting-cell">操作</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>{getEditStatus(user.is_editable)}</td>
              <td>{getEmployeeName(user.employee_id)}</td>
              <td>
                <button onClick={() => handleOpenModal(user, true)}>編集</button>
                <button className="button-del" onClick={() => handleOpenDeleteModal(user)}>削除</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <button onClick={() => handleOpenModal(null, true)}>新規追加</button>

      {showModal && (
        <div className="settings-modal-overlay">
          <div className="settings-modal-content">
            <h3 className="settings-modal-title">{isEditing ? "ユーザー編集" : "ユーザー詳細"}</h3>
            <label className="settings-modal-textinput">
              ユーザー名
              <input
                type="text"
                name="username"
                value={selectedUser?.username || ""}
                onChange={handleInputChange}
                placeholder="ユーザー名"
                disabled={!isEditing}
              />
            </label>
            <label className="settings-modal-textinput">
              email
              <input
                type="email"
                name="email"
                value={selectedUser?.email || ""}
                onChange={handleInputChange}
                placeholder="メールアドレス"
                disabled={!isEditing}
              />
            </label>
            <label className="settings-modal-textinput">
              パスワード
              <input
                type="password"
                name="password"
                value={selectedUser?.password || ""}
                onChange={(e) => setSelectedUser({ ...selectedUser, password: e.target.value })}
                placeholder="パスワード"
                disabled={!!selectedUser?.id} // 既存ユーザーの場合は編集不可
              />
            </label>
            <label className="settings-modal-textinput">
              役割
              <input
                type="text"
                name="role"
                value={selectedUser?.role || ""}
                onChange={handleInputChange}
                placeholder="役割"
                disabled={!isEditing}
              />
            </label>
            <label className="settings-modal-dropdown">
              編集可否
              <select
                name="is_editable"
                value={selectedUser?.is_editable || 0} // デフォルト値を非編集可能に設定
                onChange={(e) => setSelectedUser({ ...selectedUser, is_editable: parseInt(e.target.value, 10) })}
                disabled={!isEditing}
              >
                <option value={0}>編集不可</option>
                <option value={1}>シフト編集可</option>
                <option value={2}>root</option>
              </select>
            </label>
            <label className="settings-modal-dropdown">
              紐づける従業員
              <select
                name="employee_id"
                value={selectedUser?.employee_id || ""}
                onChange={handleInputChange}
                disabled={!isEditing}
              >
                <option value="">従業員を選択</option>
                {employees
                  .filter(emp => (!emp.is_separator || emp.is_separator === 0))
                  .map(emp => (
                    <option key={emp.id} value={emp.id}>
                      {emp.name}
                    </option>
                  ))}
              </select>
            </label>
            <div className="modal-actions">
              {isEditing && <button onClick={saveUser}>保存</button>}
              <button className="button-del" onClick={handleCloseModal}>閉じる</button>
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="settings-modal-overlay">
          <div className="settings-modal-content">
            <h3 className="settings-modal-title">確認</h3>
            <p>ユーザー「{userToDelete?.username}」を削除しますか？</p>
            <div className="settings-modal-actions">
              <button
                className="button-del"
                onClick={() => {
                  removeUser(userToDelete.id);
                  handleCloseDeleteModal();
                }}
              >
                削除
              </button>
              <button onClick={handleCloseDeleteModal}>いいえ</button>
            </div>
          </div>
        </div>
      )}
    </div>


  );
};

export default UserSettings;
