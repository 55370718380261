import React, { useState, useEffect } from 'react';
import '../styles/App.css';
import Holidays from 'date-holidays';
import ShiftTable from '../components/ShiftTable';
import ShiftModal from '../components/ShiftModal';
import Header from '../components/Header';
import SettingsPage from './SettingsPage';
import ShiftSidebar from '../components/ShiftSidebar'; // サイドバーをインポート
import { getComplementaryColor } from '../utils/colorUtils';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import { checkResponseStatus } from '../utils/tokenExpiredHandler';
import config from "../config/config";

function App() {
  //変数定義
  //ユーザー認証処理用
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [themeColor, setThemeColor] = useState('#3498db'); // デフォルトのテーマカラー
  const today = new Date();
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth() + 1);
  const [selectedCell, setSelectedCell] = useState(null);
  const [specialShift, setSpecialShift] = useState(false);
  const [selectedAdjustment, setSelectedAdjustment] = useState("none");
  const [note, setNote] = useState("");
  const [colorMode, setColorMode] = useState("custom");
  const [selectedColor, setSelectedColor] = useState("#ffffff");
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredCol, setHoveredCol] = useState(null);
  const [holidayDates, setHolidayDates] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null); // 現在のユーザー情報
  const [employees, setEmployees] = useState([]);
  const [shiftData, setShiftData] = useState([]); // 初期値は空配列
  const [shifts, setShifts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedShift, setSelectedShift] = useState(shifts.length > 0 ? shifts[0].name : '');
  const [sidebarOpen, setSidebarOpen] = useState(false); // サイドバーの開閉状態
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const minutes = [0, 15, 30, 45];
  const [endHour, setEndHour] = useState(0);
  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endMinute, setEndMinute] = useState(0);
  const [users, setUsers] = useState([]);


  //login
  const handleLoginSuccess = async () => {
    setIsAuthenticated(true);
    await fetchCurrentUser(); // ログイン後にユーザー情報を取得
  };

  //現在のユーザー情報を取得
  const fetchCurrentUser = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { 'Authorization': `Bearer ${token}` };
      const res = await fetch(`${config.apiBaseUrl}/users`, { headers });
      if (!(await checkResponseStatus(res))) return;

      const users = await res.json();
      const loggedInUser = users.find(user => user.username === localStorage.getItem('username'));
      setCurrentUser(loggedInUser); // 現在のユーザー情報を保存
    } catch (error) {
      console.error('ユーザー情報取得エラー:', error);
    }
  };

  //初期化時にもユーザー情報を取得
  useEffect(() => {
    if (isAuthenticated) {
      fetchCurrentUser(); // 初期化時にもユーザー情報を取得
    }
  }, [isAuthenticated]);

  // // 403エラー時のログアウト処理
  // const handleTokenExpired = () => {
  //   localStorage.removeItem('token');      // トークンを削除
  //   setIsAuthenticated(false);             // 認証状態をリセット
  //   setCurrentUser(null); // ユーザー情報をリセット
  //   window.location.href = '/login';       // ログイン画面にリダイレクト
  // };


  // データをオブジェクト形式に変換する関数
  const formatShiftData = (data) => {
    const formattedData = {};
    data.forEach((shift) => {
      // 日付をYYYY-MM-DD形式に変換
      const formattedDate = new Date(shift.date).toISOString().split('T')[0];
      const key = `${shift.staff_id}-${formattedDate}`;
      formattedData[key] = shift;
    });
    return formattedData;
  };

  // APIリクエスト関数
  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { 'Authorization': `Bearer ${token}` };

      // シフトデータの取得
      // console.log('シフトデータを取得中...');
      const shiftDataRes = await fetch(`${config.apiBaseUrl}/shiftData`, { headers });
      if (!(await checkResponseStatus(shiftDataRes))) return;
      const shiftDataArray = await shiftDataRes.json();
      setShiftData(formatShiftData(shiftDataArray)); // 変換したデータを設定
      // console.log('取得したshiftData:', shiftData);
      // console.log('取得したshiftDataArray:', shiftDataArray);
      // console.log('フォーマット済みshiftData:', formatShiftData(shiftDataArray));

      // 従業員データの取得
      // console.log('従業員データを取得中...');
      const employeesRes = await fetch(`${config.apiBaseUrl}/employees`, { headers });
      if (!(await checkResponseStatus(shiftDataRes))) return;
      const employeesJson = await employeesRes.json();
      setEmployees(Array.isArray(employeesJson) ? employeesJson : []);
      // console.log('取得したemployees:', employeesJson);

      // シフトコードデータの取得
      // console.log('シフトコードデータを取得中...');
      const shiftsRes = await fetch(`${config.apiBaseUrl}/shifts`, { headers });
      if (!(await checkResponseStatus(shiftDataRes))) return;
      const shiftsJson = await shiftsRes.json();
      setShifts(Array.isArray(shiftsJson) ? shiftsJson : []);
      // console.log('取得したshifts:', shiftsJson);

      setLoading(false);
    } catch (error) {
      console.error('データ取得エラー:', error);
    }
  };

  const [userList, setUserList] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // ユーザー一覧を取得
        const userRes = await fetch(`${config.apiBaseUrl}/users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // トークンを送信
          },
        });
        const users = await userRes.json();

        // ID:名前の形式でマッピング
        const userMap = users.reduce((map, user) => {
          map[user.id] = user.username;
          return map;
        }, {});
        setUserList(userMap);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchData();
  }, []);


  //初期化時にも取得
  useEffect(() => {
    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated]);

  //サイドバー開閉処理
  const openSidebar = () => setSidebarOpen(true);
  const closeSidebar = () => setSidebarOpen(false);

  //日付計算
  // const calculateDaysInRange = (year, month) => {
  //   const today = new Date();
  //   const currentDay = today.getUTCDate();

  //   // 今日が21日以降かどうか
  //   const isAfter20th = currentDay > 20;

  //   // 開始日 (start) の計算
  //   const startYear = month === 1 && !isAfter20th ? year - 1 : year;
  //   const startMonth = isAfter20th ? month - 1 : (month === 1 ? 11 : month - 2);
  //   const start = new Date(Date.UTC(startYear, startMonth, 21));

  //   // 終了日 (end) の計算
  //   const endYear = isAfter20th && month === 12 ? year + 1 : year;
  //   const endMonth = isAfter20th ? month : (month === 1 ? 12 : month - 1);
  //   const end = new Date(Date.UTC(endYear, endMonth, 20));

  //   // Invalid Dateのチェック
  //   if (isNaN(start) || isNaN(end)) {
  //     console.error("Invalid Date in calculateDaysInRange", { start, end });
  //     return [];
  //   }

  //   // 日付範囲を生成
  //   const days = [];
  //   for (let d = new Date(start); d <= end; d.setUTCDate(d.getUTCDate() + 1)) {
  //     days.push(new Date(d.getTime()));
  //   }
  //   return days;
  // };

  const calculateDaysInRange = (year, month) => {
    const today = new Date();
    const currentDay = today.getUTCDate();

    // 今日が21日以降かどうか
    const isAfter20th = currentDay > 20;

    // 開始日と終了日の計算
    const start = new Date(Date.UTC(year, month - (isAfter20th ? 1 : 2), 21)); // 前月21日
    const end = new Date(Date.UTC(year, month - (isAfter20th ? 0 : 1), 20));   // 当月20日

    // 日付が正しい範囲内に収まるよう調整
    if (start.getMonth() === -1) start.setFullYear(start.getFullYear() - 1, 11); // 前年12月
    if (end.getMonth() === 12) end.setFullYear(end.getFullYear() + 1, 0);       // 翌年1月

    // 日付範囲を生成
    const days = [];
    for (let d = new Date(start); d <= end; d.setUTCDate(d.getUTCDate() + 1)) {
      days.push(new Date(d.getTime()));
    }

    return days;
  };


  const [daysInRange, setDaysInRange] = useState(calculateDaysInRange(year, month));

  //祝日特定処理
  useEffect(() => {
    calculateHolidays();
  }, [month, year]);

  const calculateHolidays = () => {
    const hd = new Holidays('JP');
    const monthHolidays = daysInRange
      .map(date => {
        const holidays = hd.isHoliday(date);
        if (Array.isArray(holidays)) {
          // `public`の祝日をフィルタして、その日付を返す
          return holidays.find(h => h.type === "public") ? date.toISOString().split('T')[0] : null;
        }
        return null;
      })
      .filter(Boolean); // `null`を除外

    setHolidayDates(monthHolidays);
  };

  //前月ボタン
  const goToPreviousMonth = () => {
    const newMonth = month === 1 ? 12 : month - 1;
    const newYear = month === 1 ? year - 1 : year;
    setYear(newYear);
    setMonth(newMonth);
    setDaysInRange(calculateDaysInRange(newYear, newMonth)); // 再計算
  };

  //次月ボタン
  const goToNextMonth = () => {
    const newMonth = month === 12 ? 1 : month + 1;
    const newYear = month === 12 ? year + 1 : year;
    setYear(newYear);
    setMonth(newMonth);
    setDaysInRange(calculateDaysInRange(newYear, newMonth)); // 再計算
  };
  //当月ボタン
  const goToCurrentMonth = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
    setYear(currentYear);
    setMonth(currentMonth);
    setDaysInRange(calculateDaysInRange(currentYear, currentMonth)); // 再計算
  };
  //セルクリック時の処理
  // const handleCellClick = (row, col) => {
  //   const selectedEmployee = employees[row];
  //   const selectedDate = daysInRange[col].toISOString().split('T')[0];
  //   setSelectedCell({ row, col, date: selectedDate });
  //   setIsOpen(true);  // モーダルを開く
  // };

  const handleCellClick = async (row, col) => {
    const selectedEmployee = employees[row];
    const selectedDate = daysInRange[col].toISOString().split('T')[0];
    const cellKey = `${selectedEmployee.id}-${selectedDate}`;

    console.log(`Clicked cell: employeeId=${selectedEmployee.id}, date=${selectedDate}`);

    // 権限がない場合はロック処理をスキップ
    if (currentUser?.is_editable === 0) {
      console.log('User does not have edit permissions. Skipping lock process.');
      setSelectedCell({
        row,
        col,
        date: selectedDate,
        lockInfo: null, // ロック情報なし
      });
      setIsOpen(true); // モーダルを開く
      return;
    }

    try {
      // ロック状態を確認
      console.log(`Checking lock status for ${cellKey}`);
      const res = await fetch(`${config.apiBaseUrl}/shiftLock/status/${cellKey}`);
      const lockInfo = await res.json();
      console.log('Lock status:', lockInfo);

      if (lockInfo.isLocked && lockInfo.lockedBy !== currentUser.id) {
        console.warn(`Cell is locked by another user: ${lockInfo.lockedBy}`);
        setSelectedCell({
          row,
          col,
          date: selectedDate,
          lockInfo, // ロック情報をモーダルに渡す
        });
        setIsOpen(true); // モーダルを開く
        return;
      }

      // ロックを取得
      console.log(`Locking cell ${cellKey} for user ${currentUser.id}`);
      await fetch(`${config.apiBaseUrl}/shiftLock/lock`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ resourceId: cellKey, userId: currentUser.id }),
      });

      setSelectedCell({
        row,
        col,
        date: selectedDate,
        lockInfo: null, // ロック情報は不要（自分がロック中）
      });
      setIsOpen(true); // モーダルを開く
    } catch (error) {
      console.error('Error during lock check or lock acquisition:', error);
    }
  };

  //モーダルを閉じる処理
  const handleConfirm = () => {
    setIsOpen(false);  // モーダルを閉じる
    setSelectedCell(null);  // 選択セルをクリア
  };

  const handleModalClose = async () => {
    if (selectedCell) {

      const cellKey = `${employees[selectedCell.row].id}-${selectedCell.date}`;
      console.log(`Releasing lock for cellKey: ${cellKey}`);
      // 権限がない場合はロック解除処理をスキップ
      if (currentUser?.is_editable === 0) {
        console.log('User does not have edit permissions. Skipping unlock process.');
        setIsOpen(false);
        setSelectedCell(null);
        return;
      }
      try {
        // ロック解除APIを呼ぶ
        await fetch(`${config.apiBaseUrl}/shiftLock/unlock`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ resourceId: cellKey, userId: currentUser.id }),
        });
        console.log(`Lock released for ${cellKey}`);
      } catch (error) {
        console.error('Failed to release lock:', error);
      }
    }

    // モーダルを閉じる
    setIsOpen(false);
    setSelectedCell(null); // 選択状態をリセット
  };


  //セルへのシフト表示処理
  const getShiftDisplay = (row, col) => {
    const cellData = shiftData[`${row}-${col}`];

    if (typeof cellData === 'object') {
      const iconColor = cellData.color ? getComplementaryColor(cellData.color) : "#000000";

      const hasNoteMarker = cellData.note ? (
        <span className="note-marker" style={{ color: iconColor }}>●</span>
      ) : null;

      if (cellData.custom) {
        return (
          <>
            {cellData.startTime}
            <br />
            {cellData.endTime}
            {hasNoteMarker}
          </>
        );
      } else {
        const selectedShift = shifts.find(option => option.name === cellData.shift);
        const shiftLabel = selectedShift ? selectedShift.shortLabel : cellData.shift;
        const adjustmentSymbol = cellData.adjustment === "plus" ? "+"
          : cellData.adjustment === "minus" ? "-"
            : "";
        return (
          <>
            {`${shiftLabel} ${adjustmentSymbol}`.trim()}
            {hasNoteMarker}
          </>
        );
      }
    }
    return "";
  };

  //表上部の月表示用
  // const calculateShiftMonth = (currentYear, currentMonth) => {
  //   const today = new Date();
  //   const currentDay = today.getDate();

  //   // 今日が21日以降かどうか
  //   const isAfter20th = currentDay > 20;

  //   // 表示する月の計算
  //   const shiftYear = isAfter20th ? currentYear : (currentMonth === 1 ? currentYear - 1 : currentYear);
  //   const shiftMonth = isAfter20th ? (currentMonth === 12 ? 1 : currentMonth + 1) : currentMonth;

  //   return { shiftYear, shiftMonth };
  // };

  const calculateShiftMonth = (currentYear, currentMonth) => {
    const today = new Date();
    const currentDay = today.getDate();
  
    // 今日が21日以降かどうか
    const isAfter20th = currentDay > 20;
  
    let shiftMonth, shiftYear;
  
    if (isAfter20th) {
      // 今日が21日以降の場合
      shiftMonth = currentMonth === 12 ? 1 : currentMonth + 1;
      shiftYear = currentMonth === 12 ? currentYear + 1 : currentYear;
    } else {
      // 今日が21日以前の場合
      shiftMonth = currentMonth;
      shiftYear = currentYear;
    }
  
    return { shiftYear, shiftMonth };
  };
  


  // 表示する月の計算
  const { shiftYear, shiftMonth } = calculateShiftMonth(year, month);



  ////////////////
  ////HTML描画////
  ////////////////


  return (
    <Router>
      <div className="App">
        <Header
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
          themeColor={themeColor}
          currentUser={currentUser}
          employees={employees}
        />
        <Routes>
          <Route
            path="/login"
            element={isAuthenticated ? <Navigate to="/" /> : <LoginForm onLoginSuccess={handleLoginSuccess} />}
          />
          <Route path="/" element={
            isAuthenticated ? (
              <div>
                <h1>{shiftYear}年{shiftMonth}月 シフト管理表</h1>
                <div className="container">
                  <div className="button-container no-print">
                    <button onClick={goToPreviousMonth}>前月</button>
                    <button onClick={goToCurrentMonth}>当月</button>
                    <button onClick={goToNextMonth}>次月</button>
                  </div>
                  <ShiftTable
                    employees={employees}
                    daysInRange={daysInRange}
                    shiftData={shiftData}
                    getShiftDisplay={getShiftDisplay}
                    handleCellClick={handleCellClick}
                    hoveredRow={hoveredRow}
                    hoveredCol={hoveredCol}
                    holidayDates={holidayDates}
                    shifts={shifts}
                    setHoveredRow={setHoveredRow}
                    setHoveredCol={setHoveredCol}
                    currentUser={currentUser}
                    setShiftData={setShiftData}
                    shiftYear={shiftYear} // 年を渡す
                    shiftMonth={shiftMonth} // 月を渡す
                  />
                </div>
                {selectedCell && (

                  <ShiftModal
                    isOpen={isOpen}
                    selectedCell={selectedCell}
                    // onConfirm={handleConfirm}
                    // onCancel={() => setSelectedCell(null)}
                    onConfirm={handleModalClose} // 確定後にモーダルを閉じてロック解除
                    onCancel={handleModalClose}  // キャンセル時も同様
                    shifts={shifts}
                    specialShift={specialShift}
                    setSpecialShift={setSpecialShift}
                    selectedShift={selectedShift}
                    setSelectedShift={setSelectedShift}
                    selectedAdjustment={selectedAdjustment}
                    setSelectedAdjustment={setSelectedAdjustment}
                    colorMode={colorMode}
                    setColorMode={setColorMode}
                    selectedColor={selectedColor}
                    setSelectedColor={setSelectedColor}
                    note={note}
                    setNote={setNote}
                    employees={employees}
                    daysInRange={daysInRange}
                    hours={hours}
                    minutes={minutes}
                    startHour={startHour}
                    endHour={endHour}
                    startMinute={startMinute}
                    endMinute={endMinute}
                    setStartHour={setStartHour}
                    setEndHour={setEndHour}
                    setStartMinute={setStartMinute}
                    setEndMinute={setEndMinute}
                    setShiftData={setShiftData}
                    shiftData={shiftData}
                    currentUser={currentUser}
                    userList={userList} // ここで渡す
                  />
                )}
                <ShiftSidebar
                  shifts={shifts}
                  onClose={closeSidebar}
                  onOpen={openSidebar}
                  className={sidebarOpen ? 'open' : ''} /> {/* サイドバー */}
              </div>
            ) : (
              <Navigate to="/login" />
            )
          } />
          <Route path="/settings/*" element={

            isAuthenticated ? (
              <SettingsPage
                employees={employees}
                setEmployees={setEmployees}
                shifts={shifts}
                setShifts={setShifts}
                users={users}
                setUsers={setUsers}
                currentUser={currentUser}
              />
            ) : (
              <Navigate to="/login" />
            )
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
