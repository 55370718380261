import React, { useState } from 'react';
import { ReactComponent as LogoSVG } from '../assets/logo.svg'; // ロゴをインポート
import '../styles/LoginForm.css';
import config from "../config/config";

function LoginForm({ onLoginSuccess }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  
  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await fetch(`${config.apiBaseUrl}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('ログインに失敗しました');
      }

      const data = await response.json();
      localStorage.setItem('token', data.token); // トークンを保存
      localStorage.setItem('userId', data.user.id); // ユーザーIDを保存
      localStorage.setItem('username', data.user.username); // ユーザー名を保存
      onLoginSuccess(); // ログイン成功時の処理
    } catch (err) {
      setError(err.message);
    }
  };

  return (
      <div className="login-container">
        <div className="login-logo">
        <LogoSVG className="responsive-logo" />
        </div>
        
        <form onSubmit={handleLogin}>
          <h2>ログイン</h2>
          <div>
            <label>ユーザー名</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label>パスワード</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <button className="login-button" type="submit">ログイン</button>
        </form>
      </div>
  );
}

export default LoginForm;
