// utils/colorUtils.js

// 補色を計算する関数
export const getComplementaryColor = (color) => {
    // 16進数の色コードからRGBを取得
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    
    // 補色を計算
    const compR = 255 - r;
    const compG = 255 - g;
    const compB = 255 - b;
  
    // 補色を16進数のカラーコードに変換
    return `#${compR.toString(16).padStart(2, '0')}${compG.toString(16).padStart(2, '0')}${compB.toString(16).padStart(2, '0')}`;
  };
  