import React from 'react';
import '../styles/ShiftSidebar.css';

const ShiftSidebar = ({ shifts, onClose, className, onOpen }) => {
// 時刻をhh:mm形式にフォーマットし、先頭の0を削除
const formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(':'); // 時間と分を取り出す
  return `${parseInt(hours, 10)}:${minutes}`; // parseIntで先頭の0を削除
};

  return (
    <>
      <div className="float-tab no-print" onClick={onOpen}>
        <img src="/img/pull.png" alt="<" /> {/* タブのアイコン */}
      </div>
      <div className={`shift-sidebar ${className}`} no-print>
        <button className="close-btn" onClick={onClose}>×</button>
        <h2>シフト一覧</h2>
        <table className="sidebar-shift-table">
          <thead>
            <tr>
              <th>ラベル</th>
              <th>時間帯</th>
              <th>勤務時間</th>
            </tr>
          </thead>
          <tbody>
            {shifts.map((shift,index) => (
              <tr key={`${shift.label}-${index}`} style={{ backgroundColor: shift.color }}>
                <td style={{ backgroundColor: shift.default_color || '#ffffff'}}>{shift.shift_code}</td>
                <td>{formatTime(shift.default_start_time)}～{formatTime(shift.default_end_time)}</td>
                <td>{shift.duration_hours}h</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ShiftSidebar;