import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import '../styles/Header.css';
import config from "../config/config";
import { checkResponseStatus } from '../utils/tokenExpiredHandler';

const Header = ({ isAuthenticated, setIsAuthenticated, themeColor, currentUser, employees }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false); // メニューの開閉状態

  const handleLogout = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/auth/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      if (!(await checkResponseStatus(response))) return;
      if (response.ok) {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        navigate('/login');
      } else {
        console.error('サーバー側のログアウトに失敗しました');
      }
    } catch (error) {
      console.error('ログアウトリクエストエラー:', error);
    }
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.user-menu')) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const employeeName = employees?.find(emp => emp.id === currentUser?.employee_id)?.name || '';


  return (
    <header className="header">
      <div className="header-left">
        <div className="header-logo" onClick={() => navigate('/')}>
          <LogoSVG style={{ fill: themeColor, width: '130px', height: 'auto' }} />
        </div>
        {isAuthenticated && (
          <Link to="/" className="header-back-link">シフト表示へ戻る</Link>
        )}
      </div>
      {isAuthenticated && (
        <div className="header-right">
          <div className="user-menu" onClick={toggleMenu}>
            <span className="header-username">{currentUser?.username || 'ユーザー名不明'} {employeeName && ` (${employeeName})`}</span>
            <div className="hamburger-icon" onClick={toggleMenu}>
              ☰
            </div>
            {menuOpen && (
              <div className="dropdown-menu">
                <Link to="/settings" onClick={closeMenu}>設定</Link>
                <button onClick={handleLogout}>ログアウト</button>
              </div>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
